export default class DisplaySupportHours {
  static async use() {
    const { flag } = FeatureFlags.use()
    const { getComponentContent } = Cms.use()
    const isAep = computed(() => flag<boolean>('is-aep').value)

    const { content } = await getComponentContent('CsSupportHours')

    const hours = computed(() =>
      isAep.value ? content.value.ext.aep_hours : content.value.ext.hours
    )

    return {
      hours
    }
  }
}
